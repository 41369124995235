var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-layout-login-wraper" }, [
    _vm.displayMap.authentication
      ? _c(
          "div",
          [_c("authentication", { on: { login: _vm.loginSuccess } })],
          1
        )
      : _vm._e(),
    _vm.displayMap.authorization
      ? _c(
          "div",
          { attrs: { v: "" } },
          [
            _c("authorization", {
              attrs: { identity: _vm.identity, client: _vm.client },
              on: { authorization: _vm.authorizationSuccess }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }