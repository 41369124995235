import { render, staticRenderFns } from "./Authentication.vue?vue&type=template&id=559385fc&scoped=true&"
import script from "./Authentication.vue?vue&type=script&lang=js&"
export * from "./Authentication.vue?vue&type=script&lang=js&"
import style0 from "./Authentication.vue?vue&type=style&index=0&id=559385fc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "559385fc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1580246827/src/univoice-company-console/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('559385fc')) {
      api.createRecord('559385fc', component.options)
    } else {
      api.reload('559385fc', component.options)
    }
    module.hot.accept("./Authentication.vue?vue&type=template&id=559385fc&scoped=true&", function () {
      api.rerender('559385fc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/oauth/component/Authentication.vue"
export default component.exports