//
//
//
//
//
//
//
//
//
//
//

import { ACCESS_TOKEN, OAUTH_ACCESS_TOKEN } from '@/store/mutation-types';
import { mapMutations } from 'vuex';
import Authentication from './component/Authentication';
import Authorization from './component/Authorization';

export default {
  data() {
    return {
      loadding: false,
      identity: {},
      client: {},
      displayMap: {},
      error: false
    };
  },
  components: {
    Authentication,
    Authorization
  },
  async created() {
    try {
      this.updateDisplayMap('authentication');
      const { client_id } = this.$route.query;
      this.client = await this.$s.api.oauth.getClient(client_id);
      if (this.$ls.get(ACCESS_TOKEN) || this.$ls.get(OAUTH_ACCESS_TOKEN)) {
        this.identity = await this.$s.api.user.me();
        this.updateDisplayMap('authorization');
      }
    } catch (error) {
      this.$notification.error({
        message: this.$t(error.message.trim())
      });
      this.error = true;
    }
  },
  methods: {
    // ...mapActions('user', ['Login']),
    ...mapMutations('user', {
      saveOauthToken: 'SET_OAUTH_TOKEN'
    }),

    async loginSuccess(res) {
      if (!this.error) {
        await this.saveOauthToken(res);
        this.identity = res;
        this.updateDisplayMap('authorization');
      }
    },
    async authorizationSuccess(redirectUri) {
      await this.saveOauthToken();
      window.location.href = redirectUri;
    },
    updateDisplayMap(state) {
      this.displayMap = {
        authentication: state === 'authentication',
        authorization: state === 'authorization'
      };
    }
  }
};
