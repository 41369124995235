var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { staticClass: "mt-md" },
    [
      _c(
        "a-row",
        { attrs: { type: "flex", justify: "center", align: "middle" } },
        [
          _c(
            "a-row",
            {
              staticClass: "dash-connection",
              staticStyle: { width: "80%" },
              attrs: { type: "flex", justify: "center", align: "space-between" }
            },
            [
              _c("div", { staticClass: "circle-badge circle-badge--medium" }, [
                _c("img", {
                  staticClass: "logo",
                  attrs: { src: _vm.client.avatar, alt: "logo" }
                })
              ]),
              _c("div", { staticClass: "connection-checked" }, [
                _c(
                  "div",
                  { staticClass: "connection-checked-item" },
                  [
                    _c("a-icon", {
                      staticClass: "connection-checked-icon",
                      style: { fontSize: "36px", color: "rgb(255 255 255)" },
                      attrs: { type: "check-circle" }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "circle-badge circle-badge--medium" }, [
                _c("img", {
                  staticClass: "logo",
                  attrs: { src: require("@/assets/logo.png"), alt: "logo" }
                })
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c("div", { staticClass: "text-h6 q-my-md" }),
          _c(
            "a-form",
            { staticClass: "user-layout-login", attrs: { form: _vm.form } },
            [
              _c(
                "div",
                { staticStyle: { "font-size": "2em", "text-align": "center" } },
                [_c("strong", [_vm._v(_vm._s(_vm.client.name))])]
              ),
              _c("div", { staticStyle: { "text-align": "center" } }, [
                _vm._v("wants to access your account:")
              ]),
              _c(
                "a-row",
                {
                  staticClass: "my-sm",
                  attrs: { type: "flex", justify: "center", align: "middle" }
                },
                [
                  _c(
                    "a-avatar",
                    {
                      staticClass: "avatar mr-sm",
                      staticStyle: {
                        color: "#fff",
                        backgroundColor: "#2db7f5"
                      },
                      attrs: { size: "small" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.identity.user.email[0].toUpperCase()) +
                          " "
                      )
                    ]
                  ),
                  _c("strong", [_vm._v(_vm._s(_vm.identity.user.email))])
                ],
                1
              ),
              _c(
                "a-card",
                [
                  _c(
                    "a-row",
                    {
                      attrs: { type: "flex", justify: "start", align: "middle" }
                    },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: "3" } },
                        [
                          _c("a-avatar", {
                            staticClass: "avatar mr-sm",
                            attrs: { size: "small", icon: "user" }
                          })
                        ],
                        1
                      ),
                      _c("a-col", [
                        _c("strong", [_vm._v(" Personal user data ")]),
                        _c("br"),
                        _c("small", [
                          _vm._v(" Profile information (read-only)")
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { staticClass: "mt-sm" },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        "html-type": "submit",
                        block: "",
                        loading: _vm.loading
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.handleSubmit($event)
                        }
                      }
                    },
                    [_vm._v(" Authorize " + _vm._s(_vm.client.name) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }