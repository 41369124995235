//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ACCESS_TOKEN, OAUTH_ACCESS_TOKEN } from '@/store/mutation-types';

export default {
  props: {
    identity: {
      type: Object,
      default: () => ({})
    },
    client: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      form: this.$form.createForm(this, { name: 'authorize' })
    };
  },
  async created() {},
  methods: {
    async handleSubmit() {
      try {
        this.loading = true;
        let accessToken = this.$ls.get(ACCESS_TOKEN) || this.$ls.get(OAUTH_ACCESS_TOKEN);
        let urlParams = this.$route.query;
        let data = await this.$s.api.oauth.authorize(accessToken, urlParams);
        this.$emit('authorization', data.redirectUri);
      } catch (error) {
        this.$notification.error({
          message: this.$t(error.message.trim())
        });
      }
      this.loading = false;
    }
  }
};
