//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      loadding: false,
      form: this.$form.createForm(this, { name: 'user_login' })
    };
  },
  methods: {
    handleSubmit() {
      const {
        form: { validateFields }
      } = this;

      validateFields(async (err, values) => {
        this.loadding = true;
        try {
          if (!err) {
            let result = await this.$s.api.user.login(values);
            if (result) this.$emit('login', result);
          }
        } catch (error) {
          console.log(error);
          this.$message.error(this.$tc('Login failed'), 3, () => {});
        }
        this.loadding = false;
      });
    },
    forgot() {
      this.$router.push({
        path: '/user/forgot',
        query: { redirect: this.$route.fullPath }
      });
    },
    signUp() {
      this.$router.push({
        path: '/user/request',
        query: { redirect: this.$route.fullPath }
      });
    }
  }
};
